

import { Vue, Component } from 'vue-property-decorator'

@Component
export default class EvaluationProjectView extends Vue {
  detail = {}
  tableData: any = []
  colors = ['#25FFDE', '#25D9FF', '#EC6440', '#fb1c12']

  created () {
    this.loadEvaluationTerm()
  }

  loadEvaluationTerm () {
    this.$axios.get(this.$apis.evaluation.selectYhEvaluationByYhEvaluationId, {
      evaluationId: this.$route.query.evaluationId
    }).then(res => {
      this.detail = res
      const items: any = []
      res.yhEvaluationScoreTree.forEach((item: any) => {
        if (item.childNode && item.childNode.length) {
          item.childNode.forEach((childNode: any, index: number) => {
            items.push({
              colspan: index === 0 ? item.childNode.length : 0,
              termId: item.termId,
              termName: item.termName, // 考评项名称
              termScore: childNode.termScore,
              termWeight: item.termWeight, // 考评权重
              termChildName: childNode.termName,
              parentTermId: item.termId,
              finalScore: item.termScore,
              termScoreReason: childNode.termScoreReason,
              termDetailed: childNode.termDetailed // 考评项细则
            })
          })
        }
      })
      this.tableData = items
    })
  }

  // 下载
  onDownload (file: any) {
    window.location.href = `${file.filePrefix}${file.fileUrl}?attname=${file.fileName}`
  }

  objectSpanMethod ({ row, column, rowIndex, columnIndex }: any) {
    if (columnIndex === 0 || columnIndex === 1 || columnIndex === 5) {
      if (row.colspan > 0) {
        return {
          rowspan: row.colspan,
          colspan: 1
        }
      } else {
        return {
          rowspan: 0,
          colspan: 0
        }
      }
    }
  }
}
